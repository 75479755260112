/* eslint-disable @next/next/no-img-element */
import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { NextPageWithLayout } from 'next';

import Image from 'components/image';

import LandingPageLayout from 'components/layout/landing/LandingPageLayout';
import nonAuth from 'utils/nonAuth';

import ListItemIcon from 'public/images/landing/list_item_icon.svg';
import PlayStoreIcon from 'public/images/download-playstore.svg';
import ProfileSetupIcon from 'public/images/landing2/profile-setup.svg';
import JobApplicationIcon from 'public/images/landing2/job-application.svg';
import CommunityIcon from 'public/images/landing2/icon_community.svg';
import PhotoMan1 from 'public/images/landing2/photo/1.png';
import PhotoMan2 from 'public/images/landing2/photo/2.png';
import PhotoMan3 from 'public/images/landing2/photo/3.png';
import SeafarerActive from 'public/images/landing2/icon_seafarer.svg';
import SeafarerNotActive from 'public/images/landing2/icon_seafarer-grey.svg';
import ShipActive from 'public/images/landing2/icon_offshore-company.svg';
import ShipNotActive from 'public/images/landing2/icon_offshore-company-grey.svg';
import Link from 'next/link';
import { PlayStoreLink } from 'utils/constants';
import { useQuery } from 'react-query';
import axios from 'axios';

const FeaturesSection = () => {
  const router = useRouter();

  const [currentSection, setCurrentSection] = useState<'seafarer' | 'company'>(
    'seafarer'
  );

  useEffect(() => {
    const hash = router.asPath.split('#')?.[1];
    if (hash === 'seafarer' || hash === 'company') {
      setCurrentSection(hash);
    }
  }, [router.asPath]);

  return (
    <section className="pt-64 -mt-64 relative" id="seafarer">
      <div id="company" className="absolute top-0" />
      <div className="bg-white px-16 py-24 pb-60 ipad:px-96">
        <div className="flex border-b border-primary">
          <div
            className={`flex w-1/2 gap-8 cursor-pointer ${
              currentSection === 'seafarer' && 'border-b-2 border-primary'
            }`}
            onClick={() => setCurrentSection('seafarer')}
          >
            <div className="flex items-center">
              <Image
                src={
                  currentSection === 'seafarer'
                    ? SeafarerActive
                    : SeafarerNotActive
                }
                height={64}
                width={64}
                alt="illustration"
              />
            </div>
            <div className="flex flex-col justify-center">
              <p
                className={`italic ${
                  currentSection === 'seafarer'
                    ? 'text-primary'
                    : 'text-grey-dark'
                }`}
              >
                Our Solutions for
              </p>
              <p
                className={`text-xxlarge3 ipad:text-title3 font-semibold ${
                  currentSection === 'seafarer'
                    ? 'text-primary'
                    : 'text-grey-dark'
                }`}
              >
                Seafarers
              </p>
            </div>
          </div>

          <div
            className={`flex w-1/2 gap-8 cursor-pointer ${
              currentSection === 'company' && 'border-b-2 border-primary'
            }`}
            onClick={() => setCurrentSection('company')}
          >
            <div className="flex items-center">
              <Image
                src={currentSection === 'company' ? ShipActive : ShipNotActive}
                height={64}
                width={64}
                alt="illustration"
              />
            </div>
            <div className="flex flex-col justify-center">
              <p
                className={`italic ${
                  currentSection === 'company'
                    ? 'text-primary'
                    : 'text-grey-dark'
                }`}
              >
                Our Solutions for
              </p>
              <p
                className={`text-xxlarge3 ipad:text-title3 font-semibold ${
                  currentSection === 'company'
                    ? 'text-primary'
                    : 'text-grey-dark'
                }`}
              >
                Company
              </p>
            </div>
          </div>
        </div>

        <div className="pt-24 ipad:pt-40">
          {currentSection === 'seafarer' ? (
            <>
              <div className="flex flex-col gap-16 mb-16 ipad:mb-40 ipad:flex-row">
                <div className="flex flex-col gap-8 bg-content p-16 rounded-xl ipad:p-24 ipad:w-1/3">
                  <p className="text-primary text-regular3 font-semibold ipad:text-xxlarge3">
                    Trustworthy
                    <br />
                    Job Portal
                  </p>

                  <div className="border-t border-primary" />

                  <div className="flex items-start gap-8">
                    <div className="flex-shrink-0">
                      <Image
                        src={ListItemIcon}
                        height={24}
                        width={24}
                        alt="illustration"
                      />
                    </div>
                    <p className="text-ink-lighter">
                      Browse job openings from trustworthy companies
                    </p>
                  </div>
                  <div className="flex items-start gap-8">
                    <div className="flex-shrink-0">
                      <Image
                        src={ListItemIcon}
                        height={24}
                        width={24}
                        alt="illustration"
                      />
                    </div>
                    <p className="text-ink-lighter">
                      Submit and track the status of your job applications in
                      real-time
                    </p>
                  </div>
                </div>

                <div className="flex flex-col gap-8 bg-content p-16 rounded-xl ipad:p-24 ipad:w-1/3">
                  <p className="text-primary text-regular3 font-semibold ipad:text-xxlarge3">
                    Simplified Maritime
                    <br />
                    Career Management
                  </p>

                  <div className="border-t border-primary" />

                  <div className="flex items-start gap-8">
                    <div className="flex-shrink-0">
                      <Image
                        src={ListItemIcon}
                        height={24}
                        width={24}
                        alt="illustration"
                      />
                    </div>
                    <p className="text-ink-lighter">
                      Store your documents securely in one place with OnBoard’s
                      secure storage system
                    </p>
                  </div>
                  <div className="flex items-start gap-8">
                    <div className="flex-shrink-0">
                      <Image
                        src={ListItemIcon}
                        height={24}
                        width={24}
                        alt="illustration"
                      />
                    </div>
                    <p className="text-ink-lighter">
                      Get notifications for expiring documents
                    </p>
                  </div>
                </div>

                <div className="flex flex-col gap-8 bg-content p-16 rounded-xl ipad:p-24 ipad:w-1/3">
                  <p className="text-primary text-regular3 font-semibold ipad:text-xxlarge3">
                    Join the
                    <br />
                    OnBoard community
                  </p>

                  <div className="border-t border-primary" />

                  <div className="flex items-start gap-8">
                    <div className="flex-shrink-0">
                      <Image
                        src={ListItemIcon}
                        height={24}
                        width={24}
                        alt="illustration"
                      />
                    </div>
                    <p className="text-ink-lighter">
                      Be in the network of professional seafarers and find
                      verified job openings
                    </p>
                  </div>
                  <div className="flex items-start gap-8">
                    <div className="flex-shrink-0">
                      <Image
                        src={ListItemIcon}
                        height={24}
                        width={24}
                        alt="illustration"
                      />
                    </div>
                    <p className="text-ink-lighter">
                      Stay informed with relevant industry insights
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-16 ipad:flex-row">
                <div className="border border-primary-light bg-grey-lighter rounded-r-lg rounded-tl-lg flex gap-16 p-16 ipad:w-2/3">
                  <div className="w-full ipad:w-[200px] flex justify-center items-center">
                    <Image
                      src={PhotoMan1}
                      height={100}
                      width={100}
                      alt="profile-photo"
                      className="rounded-full"
                    />
                  </div>
                  <div className="flex flex-col justify-center gap-20">
                    <p className="italic text-primary text-large3 font-medium">
                      &quot;This app helps me to organize my documents, make
                      sure required certificates are still valid and reminds me
                      when certificates are about to expire.&quot;
                    </p>
                    <p className="text-primary font-semibold">
                      AA, 3rd Engineer{' '}
                    </p>
                  </div>
                </div>

                <div className="flex justify-center gap-20 rounded-lg bg-primary p-20 ipad:p-40 ipad:w-1/3">
                  <Link href={PlayStoreLink} passHref>
                    <a>
                      <Image
                        src={PlayStoreIcon}
                        alt="download"
                        layout="fixed"
                        height={50}
                        className="w-1/2"
                      />
                    </a>
                  </Link>
                  {/* <Image
                  src={AppStoreIcon}
                  alt="download"
                  layout="fixed"
                  height={50}
                  className="w-1/2"
                /> */}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-col gap-16 mb-16 ipad:mb-40 ipad:flex-row">
                <div className="flex flex-col gap-8 bg-content p-16 rounded-xl ipad:p-24 ipad:w-1/3">
                  <p className="text-primary text-regular3 font-semibold ipad:text-xxlarge3">
                    Comprehensive Seafarer
                    <br />
                    Database
                  </p>

                  <div className="border-t border-primary" />

                  <div className="flex items-start gap-8">
                    <div className="flex-shrink-0">
                      <Image
                        src={ListItemIcon}
                        height={24}
                        width={24}
                        alt="illustration"
                      />
                    </div>
                    <p className="text-ink-lighter">
                      Centralize and streamlines the recruitment process
                    </p>
                  </div>
                </div>

                <div className="flex flex-col gap-8 bg-content p-16 rounded-xl ipad:p-24 ipad:w-1/3">
                  <p className="text-primary text-regular3 font-semibold ipad:text-xxlarge3">
                    Industry-Specific Job
                    <br />
                    Matching Algorithm
                  </p>

                  <div className="border-t border-primary" />

                  <div className="flex items-start gap-8">
                    <div className="flex-shrink-0">
                      <Image
                        src={ListItemIcon}
                        height={24}
                        width={24}
                        alt="illustration"
                      />
                    </div>
                    <p className="text-ink-lighter">
                      Reduces search frictions and shortens lead times
                    </p>
                  </div>
                </div>

                <div className="flex flex-col gap-8 bg-content p-16 rounded-xl ipad:p-24 ipad:w-1/3">
                  <p className="text-primary text-regular3 font-semibold ipad:text-xxlarge3">
                    Multilayered Tech-Enabled
                    <br />
                    Verification
                  </p>

                  <div className="border-t border-primary" />

                  <div className="flex items-start gap-8">
                    <div className="flex-shrink-0">
                      <Image
                        src={ListItemIcon}
                        height={24}
                        width={24}
                        alt="illustration"
                      />
                    </div>
                    <p className="text-ink-lighter">
                      Ensures the quality of seafarers
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-16 ipad:flex-row">
                <div className="border border-primary-light bg-grey-lighter rounded-r-lg rounded-tl-lg flex gap-16 p-16 ipad:w-2/3 ipad:order-2">
                  <div className="w-full ipad:w-[200px] flex justify-center items-center">
                    <Image
                      src={PhotoMan3}
                      height={100}
                      width={100}
                      alt="profile-photo"
                      className="rounded-full"
                    />
                  </div>
                  <div className="flex flex-col justify-center gap-20">
                    <p className="italic text-primary text-large3 font-medium">
                      &quot;As a whole, this is a really great application and
                      platform. Seafarers will find this very helpful because of
                      services like job hunting and seafarer document
                      management. You get to control all that through one
                      app.&quot;
                    </p>
                    <p className="text-primary font-semibold">K, 3rd Officer</p>
                  </div>
                </div>

                <div className="flex flex-col gap-16 rounded-lg bg-primary p-20 ipad:p-40 ipad:w-1/3 ipad:order-1">
                  <Link href="/register">
                    <button className="bg-white w-full text-primary font-semibold text-large3 py-12 px-24 border-none rounded-lg">
                      Sign up
                    </button>
                  </Link>

                  <p className="text-center text-content">
                    Already have an account?{' '}
                    <Link href="/login">
                      <p className="font-semibold text-content underline">
                        Login here!
                      </p>
                    </Link>
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

const Home: NextPageWithLayout = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['getPartners'],
    queryFn: () =>
      axios.get('https://directus.onboardcrewapp.com/items/partners'),
  });

  const partners = useMemo(() => {
    if (!isLoading) {
      return data?.data?.data ?? null;
    }
    return null;
  }, [data, isLoading]);

  return (
    <>
      <section
        className="min-h-screen relative flex items-center bg-primary bg-no-repeat bg-cover bg-center px-16 ipad:bg-right ipad:px-96"
        style={{ backgroundImage: 'url("/images/landing/hero.png")' }}
      >
        <div className="text-left ipad:w-2/3">
          <h1 className="text-title2 font-semibold text-content mb-16 ipad:text-title1">
            Empowering Seafarers, <br />
            Revolutionizing Maritime Recruitment
          </h1>
          <p className="text-regular3 font-medium text-content mb-8 ipad:text-xxlarge2">
            Your expert maritime career partner. Clear guidance, hassle-free
            applications, and fast jobs matching
          </p>
          {/* <p className="text-regular3 text-content ipad:text-regular2">
            We navigate the tides of change, seamlessly matching skilled
            seafarers with diverse opportunities worldwide. Embrace the pinnacle
            of crewing expertise, adapting to the needs of the modern maritime
            world.
          </p> */}
        </div>
      </section>

      <section
        className="bg-primary px-16 pb-60 ipad:px-96 pt-64 -mt-64"
        id="about"
      >
        <div>
          <p className="italic text-regular2 text-content mb-16 ipad:text-title3">
            About Us
          </p>
          <div className="flex flex-col gap-8 ipad:flex-row">
            <p className="text-xxlarge2 font-semibold text-content ipad:w-1/2 ipad:text-title2">
              This is maritime recruitment reimagined by the community, for the
              community
            </p>
            <p className="text-regular3 text-content ipad:w-1/2">
              We connect seafarers with companies seamlessly, fostering trust
              and collaboration
            </p>
          </div>
        </div>

        <div className="border-t border-grey my-32" />

        <div className="mb-40">
          <p className="italic text-content text-regular2 mb-16 ipad:text-title3 ipad:mb-32">
            All-in-One Jobs Matching Services
          </p>
          <div className="flex flex-col gap-20 ipad:flex-row">
            <div className="bg-content rounded-lg p-16 ipad:w-1/3">
              <Image
                src={ProfileSetupIcon}
                height={36}
                width={36}
                alt="illustration"
              />
              <p className="text-primary text-regular3 font-semibold">
                Create and organize seafarer profiles for recruitment
              </p>
            </div>
            <div className="bg-content rounded-lg p-16 ipad:w-1/3">
              <Image
                src={JobApplicationIcon}
                height={36}
                width={36}
                alt="illustration"
              />
              <p className="text-primary text-regular3 font-semibold">
                Apply and manage job applications
              </p>
            </div>
            <div className="bg-content rounded-lg p-16 ipad:w-1/3">
              <Image
                src={CommunityIcon}
                height={36}
                width={36}
                alt="illustration"
              />
              <p className="text-primary text-regular3 font-semibold">
                Connect with the community, build a reputation, and get industry
                updates
              </p>
            </div>
          </div>
        </div>

        <div className="border border-primary-lighter rounded-r-lg rounded-tl-lg flex gap-16 p-16">
          <div className="w-full ipad:w-[200px] flex justify-center items-center">
            <Image
              src={PhotoMan2}
              height={100}
              width={100}
              alt="profile-photo"
              className="rounded-full"
            />
          </div>
          <div>
            <p className="italic text-content text-large3 font-medium mb-24">
              &quot;The OnBoard application is easy to use. Hoping the OnBoard
              app can continue to grow so that seafarers can expand their
              network through this app.&quot;
            </p>
            <p className="text-content font-semibold">IP, Chief Officer</p>
          </div>
        </div>
      </section>

      <div className="wave-container" />

      <FeaturesSection />

      {/* <div className="wave-container wave-grey" /> */}

      <section className="bg-white px-16 py-32 pb-60 ipad:px-96">
        <p className="text-title3 text-primary font-semibold text-center ipad:text-title2">
          Trusted by well-known organizations across the globe
        </p>
        <div className="pt-32 flex flex-wrap gap-y-20 justify-center">
          {partners?.map((item, index) => (
            <div
              className="basis-1/2 md:basis-1/5 px-20 cursor-pointer flex items-center justify-center"
              key={item.name}
            >
              <div
                className="max-w-[150px]"
                style={{
                  width: '100px',
                  height: '100px',
                  position: 'relative',
                }}
              >
                <a href={item.website} target="_blank">
                  <img
                    style={{
                      objectFit: 'contain',
                      width: '100px',
                      height: '100px',
                    }}
                    src={`https://directus.onboardcrewapp.com/assets/${item.logo}`}
                    alt={item.name}
                  />
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>

      <div className="wave-container wave-primary" />

      <section className="bg-primary px-16 py-60 ipad:px-96 flex flex-col items-center gap-24 ipad:gap-40">
        <p className="italic text-white font-semibold text-title3 ipad:text-title2">
          Ready to get started?
        </p>
        <div className="flex gap-20">
          <Link href={PlayStoreLink} passHref>
            <a className="border-2 border-white rounded-full py-12 px-24 text-white">
              Register as Seafarer
            </a>
          </Link>
          <Link href="/register" passHref>
            <a className="border-2 border-white rounded-full py-12 px-24 text-white">
              Register as Company
            </a>
          </Link>
        </div>
      </section>
    </>
  );
};

Home.getLayout = (page, props) => (
  <LandingPageLayout title="Home" {...props}>
    {page}
  </LandingPageLayout>
);

export default nonAuth(Home, false);
